window.onload = function(){
// Variables
const body = document.body;
const header = document.querySelector('.sol-header');
const scrollDown = 'scroll-down';
const openMenuButton = document.querySelector('.sol-open-menu-button');
const headerMenuPrimary = document.querySelector('.sol-header-menu-primary');
const headerMenuSecondary = document.querySelector('.sol-header-menu-secondary');
const headerMenuMain = document.querySelector('.sol-header-menu-main');
const closeHeaderMenuButton = document.querySelector('.sol-close-header-button');
let lastScroll = 0;

// Event Listeners
window.addEventListener('scroll', () => {
    if (window.scrollY > 300 && window.scrollY > lastScroll && !body.classList.contains(scrollDown))
        body.classList.add(scrollDown);
    else if (window.scrollY < lastScroll && body.classList.contains(scrollDown))
        body.classList.remove(scrollDown);

    lastScroll = window.scrollY;
});
window.addEventListener('keydown', (e) => {
    var code = (e.code ? e.code : e.which);
    if (code == 36)
        body.classList.remove(scrollDown);
    else if (code == 35)
        body.classList.add(scrollDown);
});
openMenuButton.addEventListener('click', function() {
    headerMenuPrimary.classList.add('translate-menu');
    headerMenuSecondary.classList.add('translate-menu');
    headerMenuMain.classList.add('translate-menu');
    body.classList.add('no-scroll');
});

closeHeaderMenuButton.addEventListener('click', function() {
    headerMenuPrimary.classList.remove('translate-menu');
    headerMenuSecondary.classList.remove('translate-menu');
    headerMenuMain.classList.remove('translate-menu');
    body.classList.remove('no-scroll');
});

/////
class TextScramble {
    constructor(el) {
        this.el = el;
        this.chars = "!<>-_\\/[]{}=+*^?#.;~____     ";
        this.update = this.update.bind(this);
    }
    setText(newText) {
        const oldText = this.el.innerText;
        const length = Math.max(oldText.length, newText.length);
        const promise = new Promise((resolve) => (this.resolve = resolve));
        this.queue = [];
        for (let i = 0; i < length; i++) {
            const from = oldText[i] || "";
            const to = newText[i] || "";
            const start = Math.floor(Math.random() * 40);
            const end = start + Math.floor(Math.random() * 40);
            this.queue.push({ from, to, start, end });
        }
        cancelAnimationFrame(this.frameRequest);
        this.frame = 0;
        this.update();
        return promise;
    }
    update() {
        let output = "";
        let complete = 0;
        for (let i = 0, n = this.queue.length; i < n; i++) {
            let { from, to, start, end, char } = this.queue[i];
            if (this.frame >= end) {
                complete++;
                output += to;
            } else if (this.frame >= start) {
                if (!char || Math.random() < 0.05) {
                    char = this.randomChar();
                    this.queue[i].char = char;
                }
                output += `<span class="dud">${char}</span>`;
            } else {
                output += from;
            }
        }
        this.el.innerHTML = output;
        if (complete === this.queue.length) {
            this.resolve();
        } else {
            this.frameRequest = requestAnimationFrame(this.update);
            this.frame++;
        }
    }
    randomChar() {
        return this.chars[Math.floor(Math.random() * this.chars.length)];
    }
}

/* TextScramble */
const el = document.querySelector(".hero-text");
if (el) {
    const fx = new TextScramble(el);

    let counter = 0;
    const heroPhrases = [
        "Information Management Systems",
        "Edu-tech",
        "eCommerce solutions",
        "Mobile Apps",
        "Agile Development",
        "Advanced Security",
    ];

    const next = () => {
        fx.setText(heroPhrases[counter]).then(() => {
            setTimeout(next, 1000);
        });
        counter = (counter + 1) % heroPhrases.length;
    };

    next();
}


/* GASP Animation */
if (gsap) {
    gsap.config({ trialWarn: false });
    var tl = gsap.timeline();
    //gsap.registerPlugin(SplitText);
    gsap.registerPlugin(ScrollTrigger);

    /* 1. Smooth scrolling */
    //const container = document.querySelector("#scroll-container");
    //function size() {
    //  const height = container.clientHeight - 15;
    //  gsap.set(document.body, { height });
    //  gsap.to(
    //    container,
    //    {
    //      y: -(height - document.documentElement.clientHeight),
    //      overwrite: "auto",
    //      duration:0.01,
    //      scrollTrigger: {
    //        trigger: document.body,
    //        start: "top top",
    //        end: "bottom bottom",
    //        scrub: 1
    //      }
    //    }
    //  );
    //}
    //window.addEventListener("resize", function(){
    //  requestAnimationFrame(size);
    //});
    //size();

    /* 2. Text Animation */
    var st = new SplitText(new SplitText('.animated-title', { type: "lines" }).lines, { type: "lines" }).lines;
    if (st.length > 0)
        gsap.set(st, { y: "105%", overwrite: true });

    ScrollTrigger.batch('.animated-title > div > div', {
        interval: 0.1,
        batchMax: 30,
        onEnter: batch => gsap.to(batch, { y: "0%", duration: 1.5, delay: .2, stagger: .1, ease: "expo.out", overwrite: true }),
        onEnterBack: batch => gsap.to(batch, { y: "0%", duration: 1.5, delay: .2, stagger: .1, ease: "expo.out", overwrite: true }),
        //onLeave:     batch => gsap.set(batch, { y: "105%", overwrite: true }),
        //onLeaveBack: batch => gsap.set(batch, { y: "105%", overwrite: true })
    });
    gsap.set('.animated-paragraph', { alpha: 0, overwrite: true });
    ScrollTrigger.batch('.animated-paragraph', {
        interval: 0.2,
        batchMax: 30,
        onEnter: batch => gsap.to(batch, { alpha: 1, duration: 1.5, delay: .2, stagger: .1, ease: "expo.out", overwrite: true }),
        onEnterBack: batch => gsap.to(batch, { alpha: 1, duration: 1.5, delay: .2, stagger: .1, ease: "expo.out", overwrite: true }),
        //onLeave:     batch => gsap.set(batch, { alpha: 0, overwrite: true }),
        //onLeaveBack: batch => gsap.set(batch, { alpha: 0, overwrite: true })
    });
}
}